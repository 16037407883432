import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import { UserButton } from '@clerk/nextjs';
import { useAuth } from '@clerk/nextjs';
import { dark as clerkDarkTheme } from '@clerk/themes';

import { LocaleSelector } from '@components/LocaleSelector';

import Logo from './partials/Logo';
import { useRouter } from 'next/router';

const Header = ({
  className,
  navPosition = '', // Default value
  hideNav = false, // Default value
  hideSignin = false, // Default value
  bottomOuterDivider = false, // Default value
  bottomDivider = false, // Default value
  ...props
}) => {
  const { t: tt } = useTranslation('header');

  const router = useRouter();

  const [isActive, setIsactive] = useState(false);
  const { isLoaded, userId } = useAuth();

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) {
      return;
    }
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    ) {
      return;
    }
    closeMenu();
  };

  return (
    <header
      {...props}
      className={clsx(
        'site-header',
        bottomOuterDivider && 'has-bottom-divider',
        className,
      )}
    >
      <div className="container">
        <div
          className={clsx(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider',
          )}
        >
          <Logo />
          <Link href="/">
            <h6>AI girlfriend WTF</h6>
          </Link>
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">{tt('menu')}</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={clsx('header-nav', isActive && 'is-active')}
              >
                <div className="header-nav-inner">
                  <ul
                    className={clsx(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`,
                    )}
                  >
                    <li>
                      <Link
                        href="/create-your-ai-girlfriend"
                        locale="en"
                        onClick={closeMenu}
                        prefetch={false}
                      >
                        {tt('create_gf')}
                      </Link>
                    </li>
                    <li>
                      <Link href="/ai-girls" onClick={closeMenu}>
                        {tt('explore_girls')}
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link href="/blog" onClick={closeMenu}>*/}
                    {/*    {tt('blog')}*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link href="/plans" onClick={closeMenu}>
                        {tt('plans')}
                      </Link>
                    </li>
                    <li>
                      <Link href="/profile" onClick={closeMenu}>
                        {tt('profile')}
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link href="/notify-me" onClick={closeMenu}>*/}
                    {/*    {tt('join')}*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {!router.pathname.startsWith('/chat') && (
                      <li className="flex flex-row justify-center items-center">
                        <LocaleSelector />
                      </li>
                    )}
                    <li className="flex flex-row justify-center items-center">
                      {isLoaded && // Ensure Clerk has loaded before rendering any button
                        (userId ? (
                          <UserButton
                            appearance={{
                              baseTheme: clerkDarkTheme,
                            }}
                            afterSignOutUrl="/"
                          />
                        ) : (
                          <Link
                            href="/sign-in"
                            onClick={closeMenu}
                            className="glow-animation xl:ms-[16px]"
                          >
                            {tt('sign_in')}
                          </Link>
                        ))}
                    </li>
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right"></ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

export default Header;
